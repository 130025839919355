import * as React from "react";
import { HtmlHead, Link } from "@cdv/jazz-web/components";
import { Typography, Box, Container, Grid } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";

export const Head = () => (
  <HtmlHead title="Výsledky projektu | Centrum dopravního výzkumu, v. v. i.">
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="description" content="Výsledky projektu" lang="cs" />
    <meta name="keywords" content="Výsledky, projektu" lang="cs" />
    <meta name="robots" content="index, follow" />
    <meta name="rating" content="general" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  </HtmlHead>
);

export default function Page() {
  const disableGrid = {
    div: {
      maxWidth: "100%",
      flexBasis: "auto",
    },
  };

  return (
    <Layout>
      <Container>
        <Box>
          <Grid container spacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item md={5} sx={{ width: "100%" }}>
              <Box sx={disableGrid}>
                <StaticImage src="../images/mapa.png" alt="mapa"></StaticImage>
              </Box>
            </Grid>
            <Grid item md={7}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                <Typography gutterBottom variant="h4" component="div">
                  Specializovaná mapa
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Na základě dat poskytnutých Drážní inspekcí byla vytvořena Mapa střetů drážních
                  vozidel s osobami, která zobrazuje následky neoprávněných vstupů chodců do
                  prostoru dráhy na území České republiky v letech 2005 až 2014. Mapa může sloužit
                  jako vodítko při identifikaci rizikových míst.
                </Typography>
              </Box>
            </Grid>
            <Grid item md={7}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                <Typography gutterBottom variant="h4" component="div">
                  Typologie rizikových lokalit
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Studie, která kategorizuje místa častého výskytu neoprávněných vstupů do prostoru
                  dráhy s ohledem na funkci, umístění, dispozici, uživatele, rizikovost a frekvenci
                  tohoto chování. Celkem bylo identifikováno šest typů rizikových lokalit: stanice a
                  zastávky, každodenní zkratky mimo stanice a zastávky, turistické stezky a
                  rekreační oblasti, cíle zájmových skupin, místa setkávání a pobytu a železniční
                  přejezdy.
                </Typography>
              </Box>
            </Grid>
            <Grid item md={5} sx={{ width: "100%" }}>
              <Box sx={disableGrid}>
                <StaticImage src="../images/typologie.png" alt="typologie"></StaticImage>
              </Box>
            </Grid>
            <Grid item md={5} sx={{ width: "100%" }}>
              <Box sx={disableGrid}>
                <StaticImage src="../images/zarizeni.jpg" alt="typologice"></StaticImage>
              </Box>
            </Grid>
            <Grid item md={7}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                <Typography gutterBottom variant="h4" component="div">
                  Zařízení varující chodce a cyklisty před vstupem do nebezpečného pásma dráhy
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Preventivní opatření byla podrobně popsána a systematicky shrnuta v metodice.
                  Metodika uvádí postup v případě zjištění zvýšeného výskytu neoprávněných vstupů
                  osob do prostoru dráhy a pro jednotlivé typy rizikových lokalit nabízí možnosti
                  vhodných nápravných opatření. Soubor opatření zahrnuje stavební, terénní a
                  technologická opatření, ale i pravidla pro aplikaci vzdělávacích opatření a zásady
                  pro prevenci vzniku rizikových míst. Metodika byla certifikována Ministerstvem
                  dopravy ČR.
                </Typography>
              </Box>
            </Grid>
            <Grid item md={7}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                <Typography gutterBottom variant="h4" component="div">
                  Metodika užití preventivních opatření proti vzniku nehod v důsledku neoprávněných
                  vstupů do prostoru dráhy
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Preventivní opatření byla podrobně popsána a systematicky shrnuta v metodice.
                  Metodika uvádí postup v případě zjištění zvýšeného výskytu neoprávněných vstupů
                  osob do prostoru dráhy a pro jednotlivé typy rizikových lokalit nabízí možnosti
                  vhodných nápravných opatření. Soubor opatření zahrnuje stavební, terénní a
                  technologická opatření, ale i pravidla pro aplikaci vzdělávacích opatření a zásady
                  pro prevenci vzniku rizikových míst. Metodika byla certifikována Ministerstvem
                  dopravy ČR.
                </Typography>
              </Box>
            </Grid>
            <Grid item md={5} sx={{ width: "100%" }}>
              <Box sx={disableGrid}>
                <StaticImage src="../images/metodika.png" alt="metodika"></StaticImage>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Layout>
  );
}
